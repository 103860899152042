import axios from "axios";
let baseURL = "";
// if (process.env.NODE_ENV === "development"){
//     baseURL = ""
// } else {
//     baseURL = ""
// }
const request = axios.create({
  baseURL: '/api',
  timeout: 3000,
});
// axios.defaults.headers.common['Authorization'] = '';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
request.interceptors.request.use(
  (config) => {
    // config.headers["Authorization"] = "";
    return config;
  },
  (error) => Promise.reject(error)
);
request.interceptors.response.use(
  (res) => {
    return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);
export function httpGet(url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "get",
      params: params,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function httPost(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: "post",
      data: data,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
